import {Button, Card, CardBody, Center, Link, Text} from '@chakra-ui/react';
import {pagesPath} from 'lib/$path';
import {MdInfoOutline, MdDiamond, MdOpenInNew} from 'react-icons/md';
import NextLink from 'next/link';
import {useOrg} from 'common/useOrg';
import {useTranslation} from 'next-i18next';
import {UserOrganizationRole} from 'lib/gql/generated';
import {usePlan} from './usePlan';

type Props = {
  target:
    | 'indexWorkerProject'
    | 'searchAnalyticsProject'
    | 'userAnalyticsProject'
    | 'job'
    | 'queryGroup'
    | 'urlGroup'
    | 'searchAnalyticsView'
    | 'user'
    | 'guest'
    | 'searchConsoleProperty'
    | 'view';
  current: number;
  onClick?: () => void;
};

/**
 * A component that displays a message that the user needs to upgrade the plan.
 * @param target - The target of the upgrade. This is used to display the appropriate message.
 * @param current - The current number of the target.
 * @param onClick - Optional. Use if you want to do something like closing the dialog on clicked the upgrade button.
 */
export const UpgradeAlert = ({target, current, onClick}: Props) => {
  const {currentOrg, currentOrgViewerRole} = useOrg();
  const {t} = useTranslation('common');
  const targetLabel = t(`plan.UpgradeAlert.targets.${target}`);
  const {plan, planLabel: planName} = usePlan();
  const max =
    target === 'indexWorkerProject'
      ? plan?.iwProjectCapacity
      : target === 'searchAnalyticsProject'
      ? plan?.scProjectCapacity
      : target === 'userAnalyticsProject'
      ? plan?.uaProjectCapacity
      : target === 'job'
      ? plan?.iwJobCapacity
      : target === 'queryGroup'
      ? plan?.queryGroupCapacity
      : target === 'urlGroup'
      ? plan?.urlGroupCapacity
      : target === 'searchAnalyticsView'
      ? plan?.viewCapacity
      : target === 'user'
      ? plan?.userCapacity
      : target === 'guest'
      ? plan?.guestCapacity
      : target === 'searchConsoleProperty'
      ? plan?.propertyCapacity
      : 0;

  return (
    <Card variant="gradient" w="400px" maxW="100%">
      <CardBody display="flex" flexDirection="column" gap="regular">
        <Text
          variant="text1Bold"
          display="flex"
          alignItems="center"
          gap="small"
        >
          <MdInfoOutline fontSize="24px" />
          {t('plan.UpgradeAlert.title', {
            target: targetLabel,
          })}
        </Text>
        <Text variant="text2" whiteSpace="pre-wrap">
          {target === 'user' || target === 'guest'
            ? t('plan.UpgradeAlert.bodyUser', {
                plan: planName,
                target: targetLabel,
                max,
                current,
              })
            : t('plan.UpgradeAlert.body', {
                plan: planName,
                target: targetLabel,
                max,
                current,
              })}
        </Text>
        {
          // if the user is not owner, the user cannot upgrade the plan.
          currentOrgViewerRole !== UserOrganizationRole.Owner ? (
            <Center>
              <Text variant="text2" color="color.attention">
                {t('plan.UpgradeAlert.askOwner')}
              </Text>
            </Center>
          ) : (
            <Center>
              <NextLink
                href={pagesPath.orgs
                  ._orgId(currentOrg?.databaseId || '')
                  .payment.$url()}
                passHref
                legacyBehavior
              >
                <Button
                  variant="amethystLight"
                  leftIcon={<MdDiamond />}
                  onClick={onClick}
                >
                  {t('plan.UpgradeAlert.buttonLabel')}
                </Button>
              </NextLink>
            </Center>
          )
        }
        <Center>
          <Link
            href="https://amethy.st/#Pricing"
            isExternal
            color="color.variation"
            display="flex"
            alignItems="center"
            gap="2px"
          >
            {t('plan.UpgradeAlert.planComparisonLink')}
            <MdOpenInNew fontSize="16px" />
          </Link>
        </Center>
      </CardBody>
    </Card>
  );
};
