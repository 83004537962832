import {decodeId, encodeId} from 'common/convertId';
import {useOrganizationQuery, useOrganizationsQuery} from 'lib/gql/generated';
import {useMemo} from 'react';
import {useRouter} from 'next/router';
import {useLocalStorageOrgId} from '../features/org/store';

export const useOrg = (args?: {
  fetchPolicy?:
    | 'cache-first'
    | 'cache-and-network'
    | 'network-only'
    | 'no-cache'
    | 'standby';
  pollInterval?: number;
}) => {
  if (!window) throw new Error('useOrg must be called in browser');

  const router = useRouter();
  // NOTE: このパラメータは、通常時は/orgs/[orgId]のorgIdになるが、
  //       onboarding時は?orgId=...となる
  const currentOrgDatabaseId = router.query.orgId as string | undefined;
  const currentOrgId = currentOrgDatabaseId
    ? encodeId('Organization', 'customers', currentOrgDatabaseId)
    : null;

  const {data, loading, refetch, stopPolling} = useOrganizationsQuery({
    fetchPolicy: args?.fetchPolicy,
    pollInterval: args?.pollInterval,
    variables: {
      organizationID: currentOrgId || '',
    },
    skip: currentOrgId === null,
  });

  const orgs = useMemo(
    () =>
      (data?.viewer?.organizations ?? []).map(org => ({
        ...org.organization,
        databaseId: decodeId(org.organizationID).docId,
        myRole: org.role,
      })),
    [data?.viewer?.organizations]
  );

  const {orgId: savedOrgId} = useLocalStorageOrgId();
  const defaultOrgId: string | null = savedOrgId ?? orgs[0]?.id ?? null;
  const defaultOrgDatabaseId = defaultOrgId
    ? decodeId(defaultOrgId).docId
    : null;

  const {data: currentOrgData} = useOrganizationQuery({
    variables: {
      id: currentOrgId ?? '',
    },
    skip: currentOrgId === null,
  });
  const currentOrg = useMemo(
    () =>
      currentOrgData?.node?.__typename === 'Organization'
        ? {...currentOrgData.node, databaseId: currentOrgDatabaseId as string}
        : null,
    [currentOrgData?.node, currentOrgDatabaseId]
  );
  const currentOrgViewerRole = orgs.find(org => org.id === currentOrgId)
    ?.myRole;
  const isAdmin = data?.viewer?.isAdmin;
  const isCustomerSupport = data?.viewer?.isCustomerSupport;

  return {
    loading,
    orgs,
    currentOrgId,
    currentOrgDatabaseId,
    currentOrg,
    currentOrgViewerRole,
    defaultOrgId,
    defaultOrgDatabaseId,
    refetch,
    stopPolling,
    isAdmin,
    isCustomerSupport,
  };
};
